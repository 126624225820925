<template>
  <div id='404'> 
    <div class='fx fx-center fx-wrap pd-15-em'> 
      <span class='wd-100 txt-align-center'> Página não encontrada </span>
      <router-link :to="{ name: 'home'}" class="fx fx-a-i-cent">
        <button class='btn mg-1-top'> Clique aqui e volte para a home</button>
      </router-link>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@media (max-width: 600px) {
  .input-quantity {
    width: 50px !important;
  }
  .hg-2 {
    height: 2em;
  }
}
.add,
.add-all {
  cursor: pointer;
  color: white;
  display: inline-block;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  background: black;
  height: 100%;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  align-items: center;
  display: flex;
  justify-content: center;
}
.subtract,
.subtract-all {
  cursor: pointer;
  color: white;
  display: inline-block;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  background: black;
  height: 100%;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  align-items: center;
  display: flex;
  justify-content: center;
}

.variation-multi-item input {
  margin-top: auto !important;
}

.product-description {
  padding-bottom: 5em;
}
.product-name-price {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24),
    0 4px 4px 0 rgba(0, 0, 0, 0.12);
}
.p-photo-name {
  display: flex;
  justify-content: flex-start;
}
.showTransitionProductDetails {
  opacity: 1;
  transition: opacity 0.5s linear;
}
.hideTransitionProductDetails {
  opacity: 0;
  pointer-events: none;
}
.hide {
  display: none;
}
.pd-fix-bar {
  padding: 0.2em;
}
.circle {
  height: 10em;
  width: 10em;
  border-radius: 100%;
  position: relative;
}
.variation-box-select {
  flex: 1;
  position: relative;
  width: 30%;
}
.variation-box-select input {
  display: none;
}
.variation-box-item {
  border: 1px solid black;
  padding: 1em;
  cursor: pointer;
}
.variation-box-item.selected {
  background-color: black;
  color: white;
  border: 1px solid white;
}
@media (max-width: 500px) {
  .subtract,
  .subtract-all,
  .add,
  .add-all {
    width: 1.25em;
    height: 1.25em;
  }

  .variation-box-select {
    width: 80%;
  }
}

p.wd-100.pre-line {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.variation-multi-item.fx.pd-1-em.fx-a-i-c {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.font-input-qtde {
  text-align: center;
  width: 30%;
  height: 2em;
}
.font-input-qtde:focus .font-input-qtde:hover {
  text-align: center;
  width: 40%;
}


</style>

<script>

import util from "@/services/util";
import Loading from "vue-loading-overlay";

export default {
  name: "404",
  template: "not-found",
  components: {
    Loading
  },
  data: function () {
    return {

    };
  },
  metaInfo() {
    return !this.product
      ? {}
      : {
          title: `404 Página não Encontrada - ${util.defaultTitle()}`,
          meta: [
            {
              vmid: "description",
              name: "description",
              content: this.product.short_description,
            },
            { vmid: "og:title", name: "og:title", content: this.product.name },
            {
              vmid: "og:description",
              name: "og:description",
              content: util.truncateBySentences(
                this.product.name + " - " + this.product.short_description,
                2
              ),
            },
            {
              vmid: "og:image",
              name: "og:image",
              content: (this.product.imageUrl || [])[0],
            },
          ],
        };
  },
  mounted() {
    this.loadIndex++;
  },
  methods: {
  },
  computed: {
    isLoading: function () {
      return this.loadIndex > 0;
    },
  },
  watch: {}
};
</script>